import React, { useContext, useMemo } from 'react'
import { useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import cn from 'classnames'
import FormTotal from './FormTotal'
import { AutoContext } from '../../contexts/AutoContext'
import Checkbox from '../base/Checkbox'
import { hints } from '../../helpers/travel'

export default function FormStep4() {
    const { modelMarkMap, debouceHandleSubmit, isEci, installmentPlan, costMonth } = useContext(AutoContext)

    const { t } = useTranslation('auto')
    const { values } = useFormikContext()
    const fullName = useMemo(() => {
        const names = {}
        values.driver.forEach((item, i) => {
            const name = [item.driverLastname, item.driverFirstname]
            if (item.driverPatr.length > 0) {
                name.push(item.driverPatr)
            }
            names[i] = name.join(' ')
        })
        return names
    }, [values.driver])

    return (
        <section className="step step-4">
            <div className="travel-one__form-items">
                <div className="travel-one__form-item">
                    <div className="auto-control__block">
                        <span className="auto-control__text">{t('beginDateIssued')}:</span>
                        <span className="auto-control__number">
                            {moment(values.beginDate).format('DD.MM.YYYY')}
                        </span>
                    </div>
                    <div className="auto__subtitle-box">
                        <h3 className="auto__subtitle">{t('automobile')}</h3>
                        <span className="auto__subtitle-decor" />
                    </div>
                    <div className="auto-control__block">
                        {
                            (values.carMark && values.carModel)
                                ? (
                                    <span className="auto-control__vin">
                                        {modelMarkMap.marks[values.carMark]}&nbsp;{modelMarkMap.model[values.carModel]}
                                    </span>
                                ) : (
                                    <span className="auto-control__vin">
                                        {values.markaTxt}&nbsp;{values.modelTxt}
                                    </span>
                                )
                        }
                        <span className="auto-control__color">
                            {values.carIssueYear} г.в.
                        </span>
                    </div>
                    <div className="auto-control__block">
                        <span className="auto-control__vin">
                            {t('vin')}: {values.vin},
                        </span>
                        <span className="auto-control__reg">
                            {t('gosNomer')}: {values.gosNomer},
                        </span>
                        <span className="auto-control__pass">
                            {t('registrSvidet')}: {values.registrSvidet}
                        </span>
                    </div>
                    <div className="auto__subtitle-box">
                        <h3 className="auto__subtitle">{t('insured')}</h3>
                        <span className="auto__subtitle-decor" />
                    </div>
                    <span className="auto-control__vin">
                        {values.holderLastname} {values.holderFirstname} {values.holderPatronymic}
                    </span>
                    <div className="auto__subtitle-box">
                        <h3 className="auto__subtitle">{t('drivers')}</h3>
                        <span className="auto__subtitle-decor" />
                    </div>
                    {
                        values.multiDriveOption === 0 && (
                            values.driver.map((item, i) => (
                                <div className="auto-control__block">
                                    <span className="auto-control__vin">
                                        {fullName[i]},
                                    </span>
                                    <span className="auto-control__color">
                                        {t('driverSeriesNumber')}: {item.docSeries} {item.docNumber},
                                    </span>
                                    <span className="auto-control__color">
                                        {t('driverPeriod')}: {t('driving_interval', {
                                            count: moment().diff(moment(item.docIssueDate), 'year'),
                                            postProcess: 'interval',
                                        })}
                                    </span>
                                </div>
                            ))
                        )
                    }
                    {
                        isEci ? (
                            <>
                                <div className="travel-one__form-row" style={{ paddingTop: '20px' }}>
                                    <Checkbox
                                        name="calculateInstallments"
                                        hint={hints.calculateInstallments}
                                        className="travel-order__text checkbox auto-rules__check"
                                        label={t('calculateInstallments')}
                                        onChange={() => debouceHandleSubmit()}
                                    />
                                </div>
                                <div className="travel-one__form-row">
                                    <div className="installment-block">
                                        {
                                            values && values.calculateInstallments && installmentPlan.map(({
                                                date,
                                                percent,
                                            }, i) => (
                                                <div className={cn('installment-block__item', { 'first-pay': i === 0 })}>
                                                    <span className="installment-block__percent">{percent}%</span>
                                                    {
                                                        i === 0 ? (
                                                            <span className="installment-block__date">
                                                                {t('payDateNow')}
                                                            </span>
                                                        ) : (
                                                            <span className="installment-block__date">
                                                                {t('payDateTo', { to: date.format('DD.MM.YYYY') })}
                                                            </span>
                                                        )
                                                    }
                                                    <span className="installment-block__summ">{costMonth[i]} TJS</span>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </>
                        ) : null
                    }
                </div>

                <FormTotal />
            </div>
        </section>
    )
}
