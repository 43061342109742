import React, { useContext, useMemo } from 'react'
import '../static/leaders.css'
import { useTranslation } from 'react-i18next'
import Layout from '../components/Layout'
import { AuthContext } from '../contexts/AuthContext'
import { useLoad } from '../hooks/request'
import { CMS } from '../utils/urls'
import { Context } from '../contexts/GlobalContext'

export default function Leaders() {
    const { t } = useTranslation('leaders')
    const { cmsToken } = useContext(AuthContext)
    const { response: leadersResponse } = useLoad({ url: CMS.LEADERS }, [cmsToken])
    const { language } = useContext(Context)
    const leaders = useMemo(() => leadersResponse && leadersResponse.map((item) => {
        const el = { photo: item.photo }
        switch (language) {
        case 'en':
            el.name = item.nameEn
            el.post = item.postEn
            break
        case 'tj':
            el.name = item.nameTj
            el.post = item.postTj
            break
        default:
            el.name = item.name
            el.post = item.post
        }
        return el
    }), [leadersResponse, language])

    return (
        <Layout>
            <div className="leaders">
                <div className="leaders__container container">
                    <h3 className="leaders__title">
                        { t('head') }
                    </h3>
                    <ul className="leaders__list">
                        {
                            leaders && leaders.map(({ name, post, photo }) => (
                                <li className="leaders__item" key={photo}>
                                    <div className="leaders__item-wrapper">
                                        <div className="leaders__item-img" style={{ backgroundImage: `url(${photo})` }} />
                                        <div className="leaders__item-text">
                                            <p className="leaders__item-job">
                                                {name}
                                            </p>
                                            <p className="leaders__item-name">
                                                {post}
                                            </p>
                                        </div>
                                    </div>
                                </li>
                            ))
                        }
                    </ul>
                </div>
            </div>
        </Layout>
    )
}
