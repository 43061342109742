import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'
import CheckboxOptions from './CheckboxOptions'
import CheckboxesOptions from './CheckboxesOptions'
import FormTotal from './FormTotal'
import { AutoContext } from '../../contexts/AutoContext'
import { createPremSum } from '../../helpers/travel'
// import { createPremSum } from '../../helpers/travel'

export default function FormStep2() {
    const { t } = useTranslation('auto')
    const { handleSubmit, validateForm, setFieldValue, values } = useFormikContext()
    const [opened, setOpened] = useState('')
    const { debouceHandleSubmit } = useContext(AutoContext)

    const selectRefs = useRef({})
    const setCompensation = useCallback((val) => {
        setFieldValue('compensation', val)
        setFieldValue('compensation0', null)
        setFieldValue('compensation1', null)
        setFieldValue('compensation2', null)
        setFieldValue('compensation3', null)
        if (val !== null) {
            window.setTimeout(() => setFieldValue(`compensation${val}`, 1), 100)
        }
    }, [])

    const options = [
        { hint: 'accidentAnyOptionHint', name: 'accidentAnyOption', prem: 'accidentDriverPrem', icon: 'crush' },
        { hint: 'accident3tpOptionHint', name: 'accident3tpOption', prem: 'accident3tpPrem', icon: 'crush' },
        { hint: 'oldNewOptionHint', name: 'oldNewOption', icon: 'pdtl', prem: 'oldNewPrem' },
        { hint: 'damageCarWashHint', name: 'damageCarWashSumAssured', icon: 'bez-gai', prem: 'damageCarWashPrem', range: createPremSum(34) },
        { hint: 'noTraficPoliceHint', name: 'noTraficPoliceSumAssured', icon: 'bez-gai', prem: 'noTraficPoliceSumPrem', range: createPremSum(34) },
    ]

    const features = [
        {
            hint: 'compensation0Hint',
            name: 'compensation0',
            icon: 'compensation',
            radio: true,
            handler: () => {
                const val = values.compensation === 0 ? null : 0
                setCompensation(val)
            },
        },
        {
            hint: 'compensation1Hint',
            name: 'compensation1',
            icon: 'expertise',
            radio: true,
            handler: () => {
                const val = values.compensation === 1 ? null : 1
                setCompensation(val)
            },
        },
        {
            hint: 'compensation2Hint',
            name: 'compensation2',
            icon: 'calculate',
            radio: true,
            handler: () => {
                const val = values.compensation === 2 ? null : 2
                setCompensation(val)
            },
        },
        {
            hint: 'compensation3Hint',
            name: 'compensation3',
            icon: 'stoa',
            radio: true,
            handler: () => {
                const val = values.compensation === 3 ? null : 3
                setCompensation(val)
            },
        },
    ]

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (opened !== '' && selectRefs.current[opened] && !selectRefs.current[opened].contains(event.target)) setOpened('')
        }

        document.addEventListener('click', handleClickOutside, true)
        return () => {
            document.removeEventListener('click', handleClickOutside, true)
        }
    }, [opened])
    useEffect(() => validateForm(), [])

    return (
        <section className="step step-3">
            <div className="travel-one__form-items">
                <div className="travel-one__form-item" onClick={() => handleSubmit()}>
                    {
                        options.map(({ hint, name, prem, icon, range, handler }) => (
                            <CheckboxOptions name={name} hint={hint} prem={prem} icon={icon} label={t(name)} onChange={() => {
                                if (handler) handler()
                                debouceHandleSubmit()
                            }} options={range} />
                        ))
                    }
                    <CheckboxesOptions name="greenCard" icon="bez-gai" label={t('greenCard')} onChange={() => {
                        handleSubmit()
                    }} options={[
                        { optionName: 'greenCardUzbOption', optionLabel: t('greenCardUzbOption'), optionPrem: 'greenCardUzbPrem' },
                        { optionName: 'greenCardKazOption', optionLabel: t('greenCardKazOption'), optionPrem: 'greenCardKazPrem' },
                        { optionName: 'greenCardKgzOption', optionLabel: t('greenCardKgzOption'), optionPrem: 'greenCardKgzPrem' },
                    ]} nameDesc={t('insuranceAreaExpansion')} hint="" />
                    <div className="auto__subtitle-box">
                        <h3 className="auto__subtitle">{t('insuranceCompensationTypes')}:</h3>
                        <span className="auto__subtitle-decor" />
                    </div>
                    {
                        features.map(({ hint, name, range, prem, icon, handler, radio }) => (
                            <CheckboxOptions name={name} hint={hint} prem={prem} icon={icon} label={t(name)} options={range} onChange={() => {
                                if (handler) handler()
                                debouceHandleSubmit()
                            }} radio={radio} />
                        ))
                    }
                </div>
                <FormTotal />
            </div>
        </section>
    )
}
