import React, { useContext, forwardRef } from 'react'
import { NavLink } from 'react-router-dom'
import Carousel from './common/Carousel'
import { useLoad } from '../hooks/request'
import { CMS } from '../utils/urls'
import Loader from './base/Loader'
import { AuthContext } from '../contexts/AuthContext'
import { Context } from '../contexts/GlobalContext'
import banner from '../static/img/banner-bg.svg'

function HomeBanner({ slug = 'glavnaya' }, ref) {
    const { language } = useContext(Context)
    const { cmsToken } = useContext(AuthContext)
    const slides = useLoad({ url: CMS.SLIDES_BY_SLUG.replace('{slug}', slug) }, [cmsToken, language])
    return (
        <section className="main-banner" ref={ref}>
            <div className="main-banner__container">
                <div>
                    {!slides.loading ? (
                        <Carousel
                            autoplay
                            arrows
                            centerMode
                            dots
                            autoplaySpeed={10000}
                            focusOnSelect={false}>
                            {slides.response ? slides.response.map((item) => (
                                <div className="main-banner-container main-banner__slider" key={item.id}>
                                    <div className="main-banner-wrapper">
                                        <div className="main-banner-slide main-banner__item">
                                            <div className="main-banner__info">
                                                <h2 className="main-banner__title">{item.title}</h2>

                                                <p className="main-banner__text">{item.description}</p>

                                                <div className="main-banner__links">
                                                    <NavLink
                                                        to={item.buttonLink}
                                                        className="main-banner__link request-link">
                                                        {item.buttonAddText}
                                                    </NavLink>

                                                    {/* <NavLink
                                                        to={item.buttonAddLink}
                                                        className="main-banner__link info-link">
                                                        {item.buttonAddText}
                                                    </NavLink> */}
                                                </div>
                                            </div>

                                            <picture className="main-banner__image">
                                                <source media="(min-width: 1280px)" srcSet={item.mainImg} />
                                                <img src={item.mainImg} alt="" />
                                            </picture>

                                            <img className="main-banner__bg" src={banner} alt="" />
                                        </div>
                                    </div>
                                    <div className="main-banner-pagination main-banner-pagination-bullets" />
                                </div>
                            )) : null}
                        </Carousel>
                    ) : <Loader centered />}
                </div>
            </div>
        </section>
    )
}

export default forwardRef(HomeBanner)
