export default {
    incorrectlyFilled: 'Нодуруст пур карда шудааст',
    continue: 'Идома',
    loading: 'Зеркашӣ...',
    wrongInput: 'Нодуруст пур карда шудааст',
    addFile: 'Замима кардани файл',
    fileHeader: 'Аксҳо аз ҷои ҳодиса ва / ё ҳуҷҷатҳои сканшуда',
    agreement1: 'Розӣ ҳастам бо шартҳои ',
    agreement2: 'коркарди маълумоти шахсӣ',
    agreement3: 'ва',
    agreement4: 'шартҳои истифодаи сомона',
    notFound_Old: 'Оҳ! Саҳифа ёфт нашуд',
    internalError: 'Хатои дохилии сервер',
    enterText: 'Ворид шудан',
    company: 'Ширкат',
    servicesText: 'Хизматрасониҳо',
    communicateText: 'Тамос бо мо',
    notFound: 'Саҳифа ефт нашуд ё дар марҳилаи таҳия',
    login: 'Ворид',
    registerText: 'Бақайдгирӣ',
    goHome: 'Ба саҳифаи асосӣ',
    partnerLinkText: 'Ба портали шарикӣ',
    enterPhone: 'Рақами телефон',
    enterPassword: 'Парол',
    forgotPassword: 'Парол фаромӯш шуд?',
    registerLinkText: 'Бақайдгирӣ',
    enterSMS: 'Pамз аз СМС',
    repeatSMS: 'Ирсоли рамз такроран пас аз',
    seconds: 'сон.',
    newPassword: 'Пароли нав',
    repeatPassword: 'Такрори парол',
    continueText: 'Идома',
    rulesText1: 'Бо пахши «Идома», ман розиям бо',
    rulesText2: 'шартҳои коркарди маълумоти шахсӣ',
    rulesText3: 'ва',
    rulesText4: 'қоидаҳои истифодаи сайт',
    registerTask: 'Ҳолати суғуртавӣ',
    offices: 'Дафтари',
    rus: 'Русӣ',
    tjs: 'Тоҷикӣ',
    eng: 'Англисӣ',
    languageSelect: 'Забон: ',
    clients: 'Ба мизоҷон',
    business: 'Бизнес',
    partners: 'Ерӣ',
    requestRegistred: 'Дархости ба қайд гирифта шудааст',
    errorExist: 'Хатогӣ ба вуҷуд омад, такрор дертар аз',
    backcallTitle: 'Перезвони ман',
    phoneInput: 'Муайян кардани рақами телефон',
    sendRequest: 'Фиристодани дархост',
    sendPay: 'Пардохт',
    sendPayed: 'Пардохтшуда',
    errorText: 'Хатогие рух дод',
}
